
try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    // require('@s/timur/src/assets/js/app');
    window.bootstrap = require('@s/timur/node_modules/bootstrap/dist/js/bootstrap.bundle.js');
    require('./bootstrap-dropdown.js');
} catch (e) { }
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

let app_id = document.head.querySelector('meta[property="og:app_id"]');

if (app_id) {
    window.fb_app_id = app_id.content
} else {
    console.error('Facebook App ID is missing some feature may not work correctly.');
}
