require('./bootstrap');
import Masonry from 'masonry-layout';
import InfiniteScroll from 'infinite-scroll';
import imagesLoaded from 'imagesloaded';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import LazyLoad from "vanilla-lazyload";
import ClipboardJS from "clipboard";
import WOW from "wow.js"
import Cookies from 'js-cookie'
import Filterizr from 'filterizr'
import PerfectScrollbar from 'perfect-scrollbar';
// import StickySidebar from 'sticky-sidebar'

const myLazyLoad = new LazyLoad({
    callback_loaded: () => {
        const msnry = Masonry.data(document.querySelector('.story-grid'))
        if (msnry) {
            msnry.layout();
        }
    }
});

const default_lang = document.documentElement.lang.substr(0, 2);
const i18n = i18next.createInstance({
    lng: default_lang,
    fallbackLng: default_lang,
    nsSeparator: '.'
}, (err, t) => {

});
$.each(vuei18nLocales, function (lng, locale) {
    $.each(locale, function (key, trans) {
        i18n.addResources(lng, key, trans);
    });
});
window.locale = i18n;
window.Filterizr = Filterizr;


const Nabersin = function () {
    const sweetDelete = function () {
        document.querySelectorAll('.warning-delete').forEach(element => element.addEventListener('click', event => {
            event.preventDefault();
            console.log(element.closest('form'));
            Swal.fire({
                title: i18n.t('i18n.areYouSureToDelete'),
                text: i18n.t('i18n.canNotRecover'),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: i18n.t('i18n.yesDeleteIt'),
                cancelButtonText: i18n.t('i18n.noCancel'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if (element.classList.contains('frm-submit')) {
                        element.closest('form').submit();
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        i18n.t('i18n.cancelled'),
                        i18n.t('i18n.operationCancelled'),
                        'error'
                    )
                }
            });

        }));

    },
        sweetTrash = function () {
            document.querySelectorAll('.warning-trash').forEach(element => element.addEventListener('click', event => {
                event.preventDefault();
                Swal.fire({
                    title: i18n.t('i18n.areYouSure'),
                    text: i18n.t('i18n.sendItemToTrash'),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: i18n.t('i18n.yesTrashIt'),
                    cancelButtonText: i18n.t('i18n.noCancel'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if (element.classList.contains('frm-submit')) {
                            element.closest('form').submit();
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            i18n.t('i18n.cancelled'),
                            i18n.t('i18n.operationCancelled'),
                            'error'
                        )
                    }
                });
            }));
        },
        initSlug = function () {
            document.querySelectorAll('.slug_title').forEach(element => element.addEventListener('change', event => {
                var title = element.value;
                var titleUrlString = Nabersin.convertToSlug(title);
                $(this).parent().parent().parent().find('.slug').val(titleUrlString);
            }));
        },
        initSocialStream = function () {
            const elems = document.querySelectorAll('.initSocial');
            const $elems = [].slice.call(elems);
            $elems.map((elem) => {
                if (elem.childNodes.length != 0) {
                    if (typeof FB !== 'undefined') {
                        FB.XFBML.parse(elem);
                        elem.classList.remove("initSocial");
                    }
                    if (typeof twttr !== 'undefined') {
                        twttr.widgets.load(elem);
                        elem.classList.remove("initSocial");
                    }
                }
            });

            if (typeof instgrm !== 'undefined') {
                instgrm.Embeds.process();
            }
        },
        resizeiFrame = function () {
            const $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com']"),
                $fluidEl = $("#app-modules");
            $allVideos.each(function () {
                let ratio = this.height / this.width
                $(this).attr('data-aspectRatio', ratio)
                if (ratio > 0) {
                    $(this).removeAttr('height').removeAttr('width');
                }
            });
            $(window).resize(function () {
                var newWidth = $fluidEl.width();
                $allVideos.each(function () {
                    var $el = $(this);
                    $el.width(newWidth).height(newWidth * $el.attr('data-aspectRatio'));
                });
            }).resize();
        },
        clipboard = function () {
            var clipboard = new ClipboardJS('.btn-clip', {
                text: function (trigger) {
                    return trigger.getAttribute('aria-label');
                }
            });
            clipboard.on('success', (e) => {
                let old = e.trigger.innerHTML;
                let text = e.trigger.dataset.copied;
                e.trigger.innerHTML = text;
                setTimeout(function () {
                    e.trigger.innerHTML = old;
                }, 2000);
            });
        },
        backToTop = function () {
            document.querySelector('body').insertAdjacentHTML("beforeend",
                "<a href='#' class='back-to-top'>" +
                "<i class='back-to-top-icon fas fa-chevron-up'></i> Back BTN" +
                "</a>"
            );

            var offset = 500;
            var duration = 800;

            window.scroll(function () {
                if (document.documentElement.scrollTop > offset) {
                    document.querySelector('.back-to-top').classList.add('show');
                } else {
                    document.querySelector('.back-to-top').classList.remove('show');
                }
            });

            document.querySelectorAll('.back-to-top').forEach(element => element.addEventListener('click', event => {
                element.stopPropagation();
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                return false;
            }));
        },
        makeSelect = function () {
            if (document.querySelectorAll('.select-all').length) {
                document.querySelectorAll('.select-all').forEach(element => element.addEventListener('click', event => {
                    element.select();
                }));
            }
        },
        makeEmbed = function () {
            if (document.getElementsByClassName('make-embed').length > 0) {
                document.querySelectorAll('.make-embed').forEach(element => element.addEventListener('click', event => {
                    let Url = element.dataset.url ? element.dataset.url : (document.getElementById('embed-url').href ? document.getElementById('embed-url').href : false);
                    let Sdk = window.variables.sdk_url;
                    console.log(Url)
                    if (Url && Sdk) {
                        const script = "<script>(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id))return;js=d.createElement(s);js.id=id;js.src='" + Sdk + "';fjs.parentNode.insertBefore(js,fjs);}(document,'script','nabersin-sdk'));</script>";
                        const html = '<div class="story-embed" data-url="' + Url + '" data-show-info="true"></div>';
                        const template = script + html;
                        document.getElementById('embed_code').value = template;

                        const imageUrl = element.dataset.cover ? element.dataset.cover : (window.postData?.embed.cover.source ? window.postData.embed.cover.source : false)
                        const title = element.dataset.title ? element.dataset.title : (window.postData?.embed.title ? window.postData.embed.title : false)
                        if (imageUrl) {
                            document.getElementById('embedHeader').style.backgroundImage = 'url(' + imageUrl + ')';
                        }
                        if (title) {
                            document.querySelector('#embedModal .modal-title').innerHTML = title;
                        }

                        const embedModel = new bootstrap.Modal(document.getElementById('embedModal'), {});
                        embedModel.show();
                    }
                }));

            }
        },
        inIframe = function () {
            if (top != self) {
                document.getElementById('embed_page').setAttribute('target', '_blank');
            } else {
                backToTop();
            }
        },
        tooltips = function () {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            })

        },
        popovers = function () {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
            var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl)
            })
        },
        initCarousel = function () {
            let items = document.querySelectorAll('.carousel-columns .carousel-item')
            if (items.length > 0) {
                items.forEach((el) => {
                    // number of slides per carousel-item
                    const minPerSlide = 5
                    let next = el.nextElementSibling
                    for (var i = 1; i < minPerSlide; i++) {
                        if (!next) {
                            // wrap carousel by using first child
                            next = items[0]
                        }
                        let cloneChild = next.cloneNode(true)
                        el.appendChild(cloneChild.children[0])
                        next = next.nextElementSibling
                    }
                })
            }
        },
        scrollables = function () {
            if (document.querySelectorAll('#sidebar').length > 0) {
                const sidebar = new PerfectScrollbar('#sidebar', {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });
            }
            if (document.querySelectorAll('.notifications--scroll').length > 0) {
                const notifications = new PerfectScrollbar('.notifications--scroll', {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });
            }
            if (document.querySelectorAll('.scrollable--nav').length > 0) {
                const scrollerNav = new PerfectScrollbar('.scrollable--nav', {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20,
                    suppressScrollY: true,
                });
            }
        },
        handleSidebar = function () {
            const menu_btn = document.querySelectorAll(".toggle-btn");
            const sidebar = document.querySelector("#sidebar");
            const container = document.querySelector(".main-wrapper");
            menu_btn.forEach(element => {
                element.addEventListener("click", () => {
                    Cookies.set('sidebar-collapse', !sidebar.classList.contains('nav-toggle'))
                    sidebar.classList.toggle("nav-toggle");
                    container.classList.toggle("active-sidebar");
                });
            });

            scrollables()
            handleSidebarResize()
            window.onresize = function () {
                handleSidebarResize();
            }
        },
        handleSidebarResize = function () {
            const sidebar = Cookies.get('sidebar-collapse')
            if (sidebar == 'true') {
                return;
            }

            if (window.matchMedia("(max-width:1200px)").matches) {
                document.querySelector(".side-navbar").classList.add('nav-toggle');
                document.querySelector(".main-wrapper ").classList.remove("active-sidebar");
            } else {
                document.querySelector(".side-navbar").classList.remove("nav-toggle");
                document.querySelector(".main-wrapper ").classList.add('active-sidebar');
            }
        },
        postVoting = function () {
            document.querySelectorAll('.voting-button').forEach(element => element.addEventListener('click', event => {
                const url = element.classList.contains('vote-global') && document.querySelector('.voting--action') ? document.querySelector('.voting--action').dataset.votingAction : window.variables.ranking_url;
                if (!url) {
                    return;
                }
                const action = element.dataset;
                const childButtons = element.classList.contains('vote-global') ? element.parentElement.parentElement.getElementsByTagName('button') : element.parentElement.getElementsByTagName('button');
                if (!element.classList.contains('user-voted')) {
                    for (let i = 0; i < childButtons.length; i++) {
                        childButtons[i].classList.remove('user-voted');
                    }
                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            element.classList.add('user-voted');
                            if (element.classList.contains('vote-global')) {
                                console.log(action.post_id)
                                document.querySelectorAll(`.vote-count-${action.post_id}`).forEach(element => {
                                    element.innerHTML = res.data.action.counts.scount;
                                });
                            } else {
                                element.parentElement.getElementsByTagName('strong').forEach(element => {
                                    element.innerHTML = res.data.action.counts.scount;
                                });
                            }
                        } else if (res.data.message) {
                            Nabersin.sweetInfo(res.data.message)
                        }
                    }).catch(err => {
                        console.log(err)
                        Nabersin.sweetError(err.response.data.message)
                        return false;
                    });
                }
            }));
        },
        flexSlider = function () {
            const slides = document.getElementsByClassName("flexbox-slide");
            if (slides.length > 0) {
                for (const slide of slides) {
                    slide.addEventListener('mouseover', () => {
                        classRemover(slides);
                        slide.classList.add('active')
                    });
                }
                function classRemover(slides) {
                    for (const slide of slides) {
                        slide.classList.remove('active');
                    }
                }
            }

        };
    return {
        init: function () {
            if (!document.querySelector('body').classList.contains('nabersin-initialized')) {
                this.initDarkMode();
                makeSelect()
                clipboard();
                sweetDelete();
                sweetTrash();
                initSlug();
                makeEmbed();
                inIframe();
                tooltips()
                popovers()
                handleSidebar()
                initCarousel()
                postVoting()
                flexSlider()
                this.logoutInit();
                this.setupSocial();
                this.socialShare();
                this.masonaryGrid();
                this.updateLazyload();
                // this.sidebarSticky();
            }
        },
        // sidebarSticky: function () {
        //     if (document.querySelector('main.main-wrapper') && (document.querySelector('main.main-wrapper').classList.contains('sticky-sidebar') && document.querySelectorAll('.aside-panal').length > 0)) {
        //         var stickySidebar = new StickySidebar('.aside-panal', {
        //             topSpacing: $('.main-wrapper .navbar.navbar-fixed').outerHeight(true) || 0,
        //             bottomSpacing: 0,
        //             resizeSensor: true,
        //             containerSelector: '#content',
        //             innerWrapperSelector: '.widgets-wrap',
        //             minWidth: 991
        //         });

        //         window.stickySidebar = stickySidebar;
        //     }
        // },
        initDarkMode: function () {
            if (!document.querySelector('.btn-mode')) {
                return;
            }
            var button = document.querySelector('.btn-mode');
            var themeMode = document.querySelector('.theme-mode');
            button.addEventListener('click', function () {
                if (themeMode.classList.contains('theme-mode-dark')) {
                    themeMode.classList.remove('theme-mode-dark')
                    themeMode.classList.add('theme-mode-light');
                } else {
                    themeMode.classList.add('theme-mode-dark')
                    themeMode.classList.remove('theme-mode-light');
                }
            });

            // change darkmode
            const STORAGE_KEY = 'siteMode';
            const modeToggleButton = document.querySelector('.js-mode-toggle');

            const applySetting = passedSetting => {
                let currentSetting = passedSetting || Cookies.get(STORAGE_KEY);
                if (currentSetting) {
                    document.documentElement.setAttribute('theme-mode', currentSetting);
                }
            };

            const toggleSetting = () => {
                let currentSetting = Cookies.get(STORAGE_KEY) === 'dark' ? 'light' : 'dark';
                Cookies.set('siteMode', currentSetting)
                return currentSetting;
            };
            modeToggleButton.addEventListener('click', evt => {
                evt.preventDefault();
                applySetting(toggleSetting());
            });
            applySetting(); // end darkmode
        },
        masonaryGrid: function () {
            if (document.querySelectorAll('.story-grid').length > 0) {
                var $grid = document.querySelector('.story-grid');
                var $masonry = new Masonry($grid, {
                    itemSelector: '.story-item',
                    columnWidth: '.story-sizer',
                    horizontalOrder: true,
                    percentPosition: true,
                });
                $masonry.layout();
                this.autoPaging($masonry);
            }

            if (document.querySelectorAll('.story-list .story-item').length > 0) {
                this.autoPaging(false);
            }
        },
        convertToSlug: function (string) {
            const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįıłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
            const b = 'aaaaaaaaacccddeeeeeeegghiiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
        },
        logoutInit: function () {
            document.querySelectorAll('.signoutBtn').forEach(element => element.addEventListener('click', event => {
                event.preventDefault();
                if (typeof FB !== 'undefined') {
                    FB.getLoginStatus(function (response) {
                        if (response && response.status === 'connected') {
                            FB.logout();
                        }
                    });
                }
                document.getElementById('logout-form').submit();
            }));
        },
        get_nav: function () {
            if (variables && variables.get_nav) {
                $('#user-nav-wrapper').load(variables.get_nav, () => {
                    this.logoutInit();
                });
            }
        },
        autoPaging: function ($masonry) {
            if (document.querySelectorAll('.auto-paging').length) {
                if ($('.auto-paging li:last-child > a').length) {
                    var options = {
                        path: '.auto-paging li:last-child > a',
                        append: '.story-item',
                        outlayer: $masonry,
                        history: false,
                        hideNav: '.auto-paging .pagination',
                        status: '.auto-paging .scroller-status',
                    };
                    InfiniteScroll.imagesLoaded = imagesLoaded;
                    if (document.querySelectorAll('.story-grid').length) {
                        var infScroll = new InfiniteScroll('.story-grid', options);
                    }
                    if (document.querySelectorAll('.story-list').length) {
                        var infScroll = new InfiniteScroll('.story-list', options);
                    }

                    infScroll.on('append', function (response, path, items) {
                        Nabersin.reinitOnRequest();
                    });
                    infScroll.on('request', function (path) {
                        Nabersin.reinitOnRequest();
                    });
                } else {
                    $('.auto-paging').hide();
                }
            }
        },
        reinitOnRequest: function () {
            myLazyLoad.update();
            // if (typeof stickySidebar !== 'undefined') {
            //     stickySidebar.updateSticky();
            // }
        },
        updateLazyload: function () {
            Nabersin.reinitOnRequest();
            $.ajaxSetup({
                complete: function () {
                    Nabersin.updateLazyload();
                }
            });
        },
        initSocial: function () {
            initSocialStream();
            resizeiFrame();
        },
        initFB: function () {
            if (window.FB && window.fb_app_id) {
                FB.init({
                    appId: fb_app_id,
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v6.0'
                });
            }
        },
        socalizePage: function () {
            $.getScript('https://assets.pinterest.com/js/pinit_main.js');
            $.getScript('https://embed.redditmedia.com/widgets/platform.js');
            $.getScript('https://embedr.flickr.com/assets/client-code.js');
            $.getScript('https://www.instagram.com/embed.js');
            $.getScript('https://platform.twitter.com/widgets.js');
            $.getScript('https://s.imgur.com/min/embed.js');
            Nabersin.initSocial();
        },
        socialShare: function () {
            var popupSize = {
                width: 780,
                height: 550
            };
            document.querySelectorAll('.social-button').forEach(element => element.addEventListener('click', event => {

                event.preventDefault();
                var verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
                    horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2),
                    url = element.href;

                var popup = window.open(url, 'social',
                    'width=' + popupSize.width + ',height=' + popupSize.height +
                    ',left=' + verticalPos + ',top=' + horisontalPos +
                    ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

                if (popup) {
                    popup.focus();
                } else {
                    var win = window.open(url, '_blank');
                    win.focus();
                }
            }));
        },
        setupSocial: function (result = false) {
            if (!document.querySelector('h1.post-title')) return;
            let Url = document.getElementById("canonical-url")?.length ? document.getElementById("canonical-url").href : window.location.href;
            var title = encodeURIComponent(document.querySelector('h1.post-title').text);
            if (result) {
                var tempUrl = new URL(Url)
                tempUrl.searchParams.append('result', result.id);
                Url = tempUrl.toString();
                if (result.title) {
                    title = result.title.replace(/<[^>]*>?/gm, '');
                }
            }

            if (((navigator.userAgent.match(/Android|iPhone/i) && !navigator.userAgent.match(/iPod|iPad/i)) ? true : false)) {
                document.querySelectorAll('.is-whatsapp').parentElement.classList.remove('d-none');
            }

            var UrlEncoded = encodeURIComponent(Url);
            $(".is-facebook").attr('href', "https://www.facebook.com/share.php?u=" + UrlEncoded + "&quote=" + title)
            $(".is-twitter").attr('href', "https://twitter.com/intent/tweet?text=" + title + " " + UrlEncoded)
            $(".is-linkedin").attr('href', "https://www.linkedin.com/shareArticle?mini=true&url=" + UrlEncoded + "&title=" + title)
            $(".is-pinterest").attr('href', "https://pinterest.com/pin/create/link/?url=" + UrlEncoded)
            $(".is-reddit").attr('href', "https://www.reddit.com/submit?url=" + UrlEncoded + "&title=" + title)
            $(".is-whatsapp").attr('href', "whatsapp://send?text=" + title + "%20%0A" + UrlEncoded)
            $(".is-email").attr('href', "mailto:?body=Take a look at this page I found: " + title + ". You can read it here: " + Url)
        },
        sweetError: function (text, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetErrors: function (html, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                html: html,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetInfo: function (text, callback, options) {
            const defaults = {
                icon: 'info',
                title: i18n.t('i18n.information'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetSuccess: function (text, callback, options) {
            const defaults = {
                icon: 'success',
                title: i18n.t('i18n.success'),
                text: text,
                timer: 2000,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetPrompt: function (text, callback, options) {
            const defaults = {
                title: text,
                input: 'text',
                confirmButtonText: i18n.t('i18n.ok'),
                showCancelButton: true,
                cancelButtonText: i18n.t('i18n.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValidator: function (value) {
                    if (!value) {
                        return i18n.t('i18n.valueIsRequired');
                    }
                }
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function (result) {
                if (result.value) {
                    typeof callback === 'function' && callback(result);
                }
            });
        },
        follow: function (action, $that) {
            if (action.detached.length) {
                $that.addClass('btn-translucent-primary').removeClass('btn-gradient').text(i18n.t('i18n.followTitle'));
            } else if (action.attached.length) {
                $that.addClass('btn-gradient').removeClass('btn-translucent-primary').text(i18n.t('i18n.followingTitle'));
            }
        },
        followable: function (url, callback) {
            if ($('.followable-button').length) {
                const $self = this;
                $(document).on('click', '.followable-button', function () {
                    const $this = $(this);

                    var action = {};
                    action['action'] = $this.data('action');
                    action['id'] = $this.data('id');
                    action['url'] = url;

                    if (action['action'] == '' || !url) {
                        $self.sweetError(i18n.t('i18n.somethingWentWrong'));
                        return false;
                    }

                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            if (action['action'] == 'follow') {
                                $self.follow(res.data.action, $this);
                            }
                            typeof callback === 'function' && callback(res.data);
                        }
                    }).catch(err => {
                        Nabersin.sweetError(err.response.data.message);
                        return false;
                    });

                    return false;
                });
            }
        },
    }
}();

var wow = new WOW({
    boxClass: 'wfit'
});
wow.init();

window.Nabersin = Nabersin;
$(function () {
    Nabersin.init();

    $.getScript('https://connect.facebook.net/en_US/sdk.js', function () {
        Nabersin.initFB();
    });

    $('body').addClass('nabersin-initialized');
});
